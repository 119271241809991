.slick-slider {position: relative;display: block;box-sizing: border-box;-moz-box-sizing: border-box;-webkit-touch-callout: none;-webkit-user-select: none;-khtml-user-select: none;-moz-user-select: none;	-ms-user-select: none;user-select: none;-ms-touch-action: pan-y;touch-action: pan-y;-webkit-tap-highlight-color: transparent;margin-bottom: 0px;}
.slick-list {position: relative;overflow: hidden;display: block;margin: 0;padding: 0}
.slick-list:focus {outline: 0}
.slick-list.dragging {cursor: pointer;cursor: hand}
.slick-slider .slick-track {-webkit-transform: translate3d(0, 0, 0);-moz-transform: translate3d(0, 0, 0);-ms-transform: translate3d(0, 0, 0);-o-transform: translate3d(0, 0, 0);transform: translate3d(0, 0, 0)}
.slick-track {position: relative;left: 0;top: 0;display: block}
.slick-track:after, .slick-track:before {content: "";display: table}
.slick-track:after {clear: both}
.slick-loading .slick-track {visibility: hidden}
.slick-slide {float: left;height: 100%;min-height: 1px;display: block;position: relative;opacity:0;visibility:hidden;}
[dir=rtl] .slick-slide {float: right}
.slick-slide img {display: block;float: left;}
.slick-slide.slick-loading img {display: none}
.slick-slide.dragging img {pointer-events: none}
.slick-initialized .slick-slide {opacity:1;visibility:visible;}
.slick-loading .slick-slide {visibility: hidden}
.slick-vertical .slick-slide {display: block;height: auto;border: 1px solid transparent}
.slick-next,.slick-prev{position:absolute;display:block;transform: translateY(-50%);margin:0px;line-height:0;font-size:0;cursor: pointer;background:0 0;color:transparent;opacity:1;padding:0;border:none;outline:0;top: 50%;z-index: 11;height: 18px;width:10px;}


.slick-next.slick-disabled:before, .slick-prev.slick-disabled:before {opacity: .25}

    .slick-prev {left: -15px;background: url('https://cdn.shopify.com/s/files/1/1073/6222/files/prev-arr.png?v=1653377706')}
.slick-next {right: -15px;background: url('https://cdn.shopify.com/s/files/1/1073/6222/files/next-arr.png?v=1653377768')}

.slick-dots {
  position: absolute;
  bottom: -40px;
  list-style: none;
  text-align: center;
  padding: 0;
  width: 100%;
  margin: 0;
  line-height: 0px;
}
.slick-dots li {position: relative;display: inline-block;margin: 0 6px;padding: 0;cursor: pointer;border-radius: 50%;}
.slick-dots li button {border: 0;background: #7B7B7B;display: block;height: 8px;width: 8px;outline: 0;line-height: 0;font-size: 0;color: transparent;padding:0px;cursor: pointer;border-radius: 50%;}
.slick-dots li button:focus, .slick-dots li button:hover {outline: 0}
.slick-dots li.slick-active button,.slick-dots li.slick-active-mobile button{background: #000000;height: 10px;width: 10px;}
.slick-dots li button:before {color: transparent;}